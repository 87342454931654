<div [formGroup]="addressForm" class="svnl-address-check">
  <mat-label>{{'funnel.generic.zipcode' | translate}}</mat-label>
  <mat-form-field>
    <input [placeholder]="'funnel.generic.zipcode' | translate" formControlName="zipCode" matInput>
    <span *ngIf="isLoading" matSuffix>
      <i class="fa-solid fa-circle-notch fa-spin"></i>
    </span>
    <mat-error *ngIf="zipcodeControl.hasError('required')">
      {{'funnel.profile.form.error.zipcode.required' | translate}}
    </mat-error>
    <mat-error *ngIf="zipcodeControl.invalid && zipcodeControl.value">
      {{'funnel.profile.form.error.zipcode.invalid' | translate}}
    </mat-error>
  </mat-form-field>
  <div *ngIf="zipIsInvalid" class="svnl-alert svnl-warning">
    {{ zipcheckErrorMessage | translate }}
  </div>

  <mat-label>{{'funnel.generic.city' | translate}}</mat-label>
  <mat-form-field>
    <mat-select (valueChange)="findByCity()" formControlName="city">
      <mat-option *ngIf="startingCityValue" [value]="cityControl.value">
        {{cityControl.value}}
      </mat-option>
      <mat-option *ngFor="let select of citySelect" [value]="select.city">
        {{select.city}}
      </mat-option>
    </mat-select>
    <span matSuffix>
      <i [ngClass]="{ valid : cityControl.valid || cityControl.disabled, invalid : cityControl.invalid }"
         class="fa fa-check-circle"></i>
    </span>
    <mat-error *ngIf="cityControl.hasError('required')">
      {{'funnel.profile.form.error.city.required' | translate}}
    </mat-error>
  </mat-form-field>

  <mat-label>{{'funnel.generic.street' | translate}}</mat-label>
  <mat-form-field>
    <mat-select (valueChange)="findByStreet()" formControlName="street">
      <mat-option *ngIf="startingStreetValue" [value]="streetControl.value">
        {{streetControl.value}}
      </mat-option>
      <mat-option *ngFor="let select of streetSelect" [value]="select.street">
        {{select.street}}
      </mat-option>
    </mat-select>
    <span matSuffix>
      <i [ngClass]="{ valid : streetControl.valid || streetControl.disabled, invalid :  streetControl.invalid }"
         class="fa fa-check-circle"></i>
    </span>
    <mat-error *ngIf="streetControl.hasError('required')">
      {{'funnel.profile.form.error.street.required' | translate}}
    </mat-error>
  </mat-form-field>

  <mat-label>{{'funnel.generic.houseNumber' | translate}}</mat-label>
  <mat-form-field>
    <input matInput formControlName="housenumber" [value]="housenumberControl.value">
    <mat-error *ngIf="housenumberControl.hasError('required')">
      {{'funnel.profile.form.error.houseNumber.required' | translate}}
    </mat-error>
  </mat-form-field>

  <mat-form-field>
    <mat-label>{{'funnel.generic.addition' | translate}}</mat-label>
    <input  [placeholder]="'funnel.generic.addition' | translate"
           formControlName="addition" matInput>
  </mat-form-field>
</div>
