import { Component } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';

import { environment } from '../../../../environments/environment';

@Component({
  selector: 'svnl-couple.dialog',
  templateUrl: './couple-account-dialog.component.html',
  styleUrls: ['./couple-account-dialog.component.scss'],
})
export class CoupleAccountDialogComponent {

  constructor(
    private readonly keycloakService: KeycloakService,
  ) {
  }

  goCouple(): void {
    window.location.href = environment.portal.coupling;
  }

  close(): void {
      if (this.keycloakService.isLoggedIn()) {
        this.keycloakService.logout();
      }
  }

}
