<div *ngIf="showHelp">
  <ng-container>
    <mat-expansion-panel class="mat-elevation-z0">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{'funnel.hsv.choose.help.link' | translate }}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <span [innerHTML]="'funnel.hsv.choose.help.body' | translate"></span>
    </mat-expansion-panel>
  </ng-container>
</div>
