import { Injectable, OnDestroy } from '@angular/core';
import { AbstractControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { AddressResult } from '@model/address-result';
import { ZipCheck } from '@model/zip-check';
import { AddressService } from '@service/address.service';
import { finalize, Subscription } from 'rxjs';

@Injectable()
export abstract class BaseCheckComponent implements OnDestroy {
  isLoading: boolean;
  zipIsInvalid: boolean;
  zipcheckErrorMessage: string;

  addressForm: FormGroup;
  streetControl: AbstractControl;
  cityControl: AbstractControl;
  zipcodeControl: AbstractControl;
  housenumberControl: AbstractControl;
  additionControl: AbstractControl;
  countryControl: AbstractControl;

  streetSelect: AddressResult[] = [];
  multipleStreets = false;

  citySelect: AddressResult[] = [];
  multipleCities = false;

  housenumberSelect: AddressResult[] = [];
  multipleHousenumbers = false;

  additionSelect: string[] = [];
  housenumberValueChangeSubscription: Subscription;
  zipcodeValueChangeSubscription: Subscription;
  countryValueChangeSubscription: Subscription;
  streetValueChangeSubscription: Subscription;

  protected constructor(
    protected readonly formGroupDirective: FormGroupDirective,
    protected readonly addressService: AddressService,
  ) {
    this.addressForm = this.formGroupDirective.control;

    this.streetControl = this.addressForm.get('street');
    this.streetControl.setValidators([
      Validators.required,
      Validators.minLength(2),
    ]);

    this.cityControl = this.addressForm.get('city');
    this.zipcodeControl = this.addressForm.get('zipCode');
    this.zipcodeControl.setValidators(Validators.required);

    this.housenumberControl = this.addressForm.get('housenumber');
    this.additionControl = this.addressForm.get('addition');
    this.countryControl = this.addressForm.get('country');

    this.manipulateControl();

    this.countryValueChangeSubscription = this.countryControl.valueChanges
      .subscribe(() => {
        this.manipulateControl();
        // Reset address fields
        this.streetControl.setValue('');
        this.cityControl.setValue('');
        this.housenumberControl.setValue('');
        this.zipcodeControl.setValue('');

        this.resetValidators();
        this.zipcodeControl.updateValueAndValidity();
      });
  }

  ngOnDestroy(): void {
    this.zipcodeValueChangeSubscription?.unsubscribe();
    this.housenumberValueChangeSubscription?.unsubscribe();
    this.streetValueChangeSubscription?.unsubscribe();
  }

  resetValidators(): void {

    if (this.countryControl.value === 'NL' ||
      this.countryControl.value === 'DE' ||
      this.countryControl.value === 'BE') {
      this.zipcodeControl.setValidators([
        Validators.required,
        Validators.pattern(this.addressService.getZipPattern(this.countryControl.value)),
      ]);
    } else {
      this.zipcodeControl.setValidators([
        Validators.required,
      ]);
    }
  }

  manipulateControl(): void {
    if (this.countryControl.value === 'NL' ||
      this.countryControl.value === 'DE') {
      this.cityControl.disable();
      this.streetControl.disable();
    }
    else if(this.countryControl.value === 'BE'){
      this.cityControl.disable();
      if(this.streetControl.value){
        this.streetControl.disable();
      }else {
        this.streetControl.enable();
      }
    }
    else {
      this.cityControl.enable();
      this.streetControl.enable();
    }
  }

  protected findByZipCode(): void {
    this.isLoading = true;

    const zipCheck: ZipCheck = {
      zipCode: this.zipcodeControl.value,
      country: this.countryControl.value,
    };

    this.addressService.canValidateAddress(zipCheck)
      .pipe(finalize(() => this.isLoading = false))
      .subscribe({
        next: addressCheck => {
          if (addressCheck.resultCount === 0) {
            this.zipIsInvalid = true;
            this.zipcheckErrorMessage = 'funnel.profile.form.address.warning.zipCode';
            this.addressForm.setErrors({ 'valid': false });
            this.streetControl.setValue(null);
            this.cityControl.setValue(null);
            this.citySelect = null;
            this.streetSelect = null;
          } else {
            this.zipIsInvalid = false;
            delete this.zipcheckErrorMessage;

            this.streetSelect = this.filterStreet(addressCheck.searchResultAddressList);
            this.multipleStreets = this.streetSelect.length > 1;

            if (this.streetSelect.length > 0) {
              this.streetControl.setValue(this.streetSelect[0].street);
              if (this.countryControl.value === 'NL') {
                if (!this.multipleStreets) {
                  this.streetControl.disable();
                } else {
                  this.streetControl.enable();
                }
              }
            } else {
              this.zipcodeControl.setErrors({ invalid: true });
            }

            this.citySelect = this.filterCities(addressCheck.searchResultAddressList);
            this.multipleCities = this.citySelect.length > 1;

            if (this.citySelect.length > 0) {
              this.cityControl.setValue(this.citySelect[0].city);
              if (this.countryControl.value === 'NL') {
                if (!this.multipleCities) {
                  this.cityControl.disable();
                } else {
                  this.cityControl.enable();
                }
              }
            }
          }
        },
        error: err => {
          console.warn('[AddressCheck] error while checking address', err);
          this.zipIsInvalid = true;
          this.zipcheckErrorMessage = err.message;
        },
      });
  }

  protected filterStreet(addressList: AddressResult[]): AddressResult[] {
    return addressList.filter((elem, index) =>
      addressList.findIndex(obj => obj.street === elem.street) === index)
      .sort((a, b) => a.street.localeCompare(b.street));
  }

  protected filterCities(addressList: AddressResult[]): AddressResult[] {
    return addressList.filter((elem, index) =>
      addressList.findIndex(obj => obj.city === elem.city) === index)
      .sort((a, b) => a.city.localeCompare(b.city));
  }

  protected filterHousenumber(addressList: AddressResult[], street: string): AddressResult[] {
    return addressList.filter(elem => elem.street === street)
      .sort((a, b) => a.streetNumber - b.streetNumber);
  }

}
