import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormGroupDirective, Validators } from '@angular/forms';
import { AddressResult } from '@model/address-result';
import { ZipCheck } from '@model/zip-check';
import { AddressService } from '@service/address.service';
import { debounceTime, finalize } from 'rxjs';

import { BaseCheckComponent } from './base-check-component';

@Component({
  selector: 'svnl-address-check',
  templateUrl: './address-check.component.html',
  styleUrls: ['./address-check.component.scss'],
})
export class AddressCheckComponent extends BaseCheckComponent implements OnInit {

  constructor(
    formGroupDirective: FormGroupDirective,
    addressService: AddressService,
  ) {
    super(formGroupDirective, addressService);
  }

  ngOnInit(): void {
    this.addValueChangeListeners();

    if (this.additionControl.value != null) {
      this.additionSelect.push(this.additionControl.value);
      this.updateAddress(this.zipcodeControl, this.housenumberControl, this.countryControl.value);
    }
  }

  private addValueChangeListeners() {
    this.zipcodeValueChangeSubscription = this.zipcodeControl.valueChanges.pipe(
      debounceTime(200),
    ).subscribe(() => this.updateAddress(this.zipcodeControl, this.housenumberControl, this.countryControl.value));

    this.housenumberValueChangeSubscription = this.housenumberControl.valueChanges.pipe(
      debounceTime(200),
    ).subscribe(() => this.updateAddress(this.zipcodeControl, this.housenumberControl, this.countryControl.value));
  }

  private updateAddress(zipCodeControl: AbstractControl, housenumberControl: AbstractControl, countryCode: string) {
    if (this.addressService.isSupportedCountry(countryCode)) {
      this.isLoading = true;

      if (zipCodeControl.valid && housenumberControl.valid) {
        const zipCheck: ZipCheck = {
          zipCode: zipCodeControl.value,
          housenumber: housenumberControl.value,
          country: this.countryControl.value,
        };

        this.addressService.canValidateAddress(zipCheck)
          .pipe(finalize(() => this.isLoading = false))
          .subscribe({
            next: addressCheck => {
              if (addressCheck.resultCount === 0) {
                this.zipIsInvalid = true;
                this.zipcheckErrorMessage = 'funnel.profile.form.address.warning.zipCode';
                this.addressForm.setErrors({ 'valid': false });
                this.streetControl.setValue(null);
                this.cityControl.setValue(null);

              } else {
                this.zipIsInvalid = false;
                this.zipcheckErrorMessage = null;
                this.additionSelect = [];
                const found = addressCheck.searchResultAddressList.find(value => +value.streetNumber === +zipCheck.housenumber);
                if (found) {
                  this.streetControl.setValue(found.street);
                  this.cityControl.setValue(found.city);
                } else {
                  this.streetControl.setValue(addressCheck.searchResultAddressList[0].street);
                  this.cityControl.setValue(addressCheck.searchResultAddressList[0].city);
                }

                if (this.countryControl.value === 'NL') {
                  this.streetControl.disable();
                  this.cityControl.disable();
                }

                if (addressCheck.resultCount > 1) {
                  this.additionSelect = this.filterAdditions(addressCheck.searchResultAddressList);
                  this.additionControl.addValidators([Validators.min(0)]);
                }

                // Clear addition value if no additions are found
                if (this.additionSelect.length === 0) {
                  this.additionControl.setValue(null);
                }
              }
            },
            error: err => {
              console.warn('[AddressCheck] error while checking address', err);
              this.zipIsInvalid = true;
              this.zipcheckErrorMessage = err.message;
            },
          });
      } else {
        this.isLoading = false;
      }
    }
  }

  private filterAdditions(addressResult: AddressResult[]): string[] {
    const result = addressResult.filter(value => value.addition != null && value.addition.length >= 0);
    result.sort((additionA, additionB) => {
      const nameA = additionA.addition.toUpperCase();
      const nameB = additionB.addition.toUpperCase();

      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;

      return 0;
    });

    return result.map(value => value.addition);
  }

}
