import { Component, OnInit } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';

import { Constants } from '../../../constants';

@Component({
  selector: 'svnl-header-home',
  templateUrl: './header-home.component.html',
  styleUrls: ['./header-home.component.scss'],
})
export class HeaderHomeComponent implements OnInit {
  isLoggedIn = false;
  permitImageSource = Constants.PERMIT_IMAGE_LOCATION;

  constructor(
    private readonly keycloakService: KeycloakService,
  ) {
  }

  ngOnInit(): void {
      this.isLoggedIn = this.keycloakService.isLoggedIn();
  }
}
