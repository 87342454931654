import { Component, OnInit } from '@angular/core';
import { OrderService } from '@service/order.service';


@Component({
  selector: 'svnl-hsv-help',
  templateUrl: './hsv-help.component.html',
  styleUrls: ['./hsv-help.component.scss'],
})
export class HsvHelpComponent implements OnInit {

  showHelp: boolean;

  constructor(
    private orderService: OrderService,
  ) {
  }

  ngOnInit(): void {
    this.showHelp = this.orderService.isPermitOrder() && !this.orderService.isYouthLicenseOrder() && !this.orderService.isSmallPermitOrder();
  }

}
