import { Component, Input, OnInit } from '@angular/core';
import { NavigationService } from '@service/navigation.service';
import { ProfileService } from '@service/profile.service';
import { SessionService } from '@service/session.service';
import { KeycloakService } from 'keycloak-angular';
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";

@Component({
  selector: 'svnl-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() wizardStep: number;

  isLoggedIn: boolean;


  constructor(
    private readonly keycloakService: KeycloakService,
    private readonly profileService: ProfileService,
    private readonly navigationService: NavigationService,
    private readonly sessionService: SessionService,
    private readonly router: Router
  ) {
  }

  ngOnInit(): void {
      this.isLoggedIn = this.keycloakService.isLoggedIn();
  }

  login(): void {
    this.keycloakService.login().finally(() => this.profileService.getRemoteProfile());
  }

  logout(): void {
    this.keycloakService.logout();
    this.sessionService.clearSession();
    this.profileService.clearProfile();
    this.navigationService.goHome();
  }

  get showLoginButton(): boolean {
    return this.router.url.includes('profile');
  }
}
