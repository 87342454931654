<div [formGroup]="addressForm" class="svnl-address-check">
  <mat-label translate="funnel.generic.zipcode"></mat-label>
  <mat-form-field>
    <input [placeholder]="'funnel.generic.zipcode' | translate"
           formControlName="zipCode" matInput
           name="zipcode"
           pattern="^[1-9][0-9]{3}\s?[A-Za-z]{2}$">
    <span *ngIf="isLoading" matSuffix>
      <i class="fa-solid fa-circle-notch fa-spin"></i>
    </span>
    <mat-error *ngIf="zipcodeControl.hasError('required')"
               translate="funnel.profile.form.error.zipcode.required">
    </mat-error>
    <mat-error *ngIf="zipcodeControl.hasError('pattern')"
               translate="funnel.profile.form.error.zipcode.invalid">
    </mat-error>
  </mat-form-field>

  <mat-label translate="funnel.generic.houseNumber"></mat-label>
  <mat-form-field>
    <input [placeholder]="'funnel.generic.houseNumber' | translate"
           formControlName="housenumber" matInput
           name="houseNumber"
           type="number">
    <span *ngIf="isLoading" matSuffix>
      <i class="fa-solid fa-circle-notch fa-spin"></i>
    </span>
    <mat-error *ngIf="housenumberControl.hasError('required')"
               translate="funnel.profile.form.error.houseNumber.required">
    </mat-error>
    <mat-error *ngIf="housenumberControl.hasError('pattern')"
               translate="funnel.profile.form.error.houseNumber.invalid">
    </mat-error>
  </mat-form-field>

  <mat-form-field *ngIf="additionSelect.length > 0">
    <mat-label translate="funnel.generic.addition" *ngIf="additionSelect.length > 0"></mat-label>
    <mat-select formControlName="addition">
      <mat-option *ngFor="let addition of additionSelect" [value]="addition">
        {{addition}}
      </mat-option>
    </mat-select>
    <mat-error *ngIf="additionControl.hasError('required')"
               translate="funnel.profile.form.error.housenumber.required">
    </mat-error>
  </mat-form-field>

  <div *ngIf="zipIsInvalid" class="svnl-alert svnl-warning">
    {{ zipcheckErrorMessage | translate }}
  </div>

  <mat-label translate="funnel.generic.street"></mat-label>
  <mat-form-field>
    <input [placeholder]="'funnel.generic.street' | translate" formControlName="street" matInput>
    <span matSuffix>
      <i
        [ngClass]="{'valid' : streetControl.disabled || streetControl.valid, 'invalid' : streetControl.invalid, 'empty' : streetControl.pristine && !streetControl.value}"
        class="fa fa-check-circle"></i>
    </span>
    <mat-error *ngIf="streetControl.hasError('required')"
               translate="funnel.profile.form.error.street.required">
    </mat-error>
  </mat-form-field>

  <mat-label translate="funnel.generic.city"></mat-label>
  <mat-form-field>
    <input [placeholder]="'funnel.generic.city' | translate" formControlName="city" matInput>
    <span matSuffix>
      <i
        [ngClass]="{'valid' : cityControl.disabled || cityControl.valid, 'invalid' : cityControl.invalid, 'empty' : cityControl.pristine && !cityControl.value}"
        class="fa fa-check-circle"></i>
    </span>
    <mat-error *ngIf="cityControl.hasError('required')" translate="funnel.profile.form.error.city.required"></mat-error>
  </mat-form-field>
</div>
