import { Component, OnInit } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';
import { ZipCheck } from '@model/zip-check';
import { AddressService } from '@service/address.service';
import { filter, finalize } from 'rxjs';

import { BaseCheckComponent } from './base-check-component';

@Component({
  selector: 'svnl-belgium-address-check',
  templateUrl: './belgium-address-check.component.html',
  styleUrls: ['./address-check.component.scss'],
})
export class BelgiumAddressCheckComponent extends BaseCheckComponent implements OnInit {

  startingStreetValue: string;
  startingCityValue: string;
  startingHouseNumberValue: string;

  constructor(
    formGroupDirective: FormGroupDirective,
    addressService: AddressService,
  ) {
    super(formGroupDirective, addressService);
  }

  ngOnInit(): void {
    this.startingStreetValue = this.streetControl.value;
    this.startingCityValue = this.cityControl.value;
    this.startingHouseNumberValue = this.housenumberControl.value;
    this.resetValidators();
    this.addValueChangeListeners();
  }

  ngDestroy(): void {
    this.zipcodeValueChangeSubscription?.unsubscribe();
  }

  findByCity(): void {
    const zipCheck: ZipCheck = {
      zipCode: this.zipcodeControl.value,
      city: this.cityControl.value,
      country: this.countryControl.value,
    };

    this.addressService.canValidateAddress(zipCheck)
      .pipe(finalize(() => this.isLoading = false))
      .subscribe({
        next: value => {
          this.streetSelect = this.filterStreet(value.searchResultAddressList);

          this.multipleStreets = this.streetSelect.length > 1;

          if (this.streetSelect.length > 0) {
            this.streetControl.setValue(this.streetSelect[0].street);
          }
        },
      });
  }

  findByStreet(): void {
    const zipCheck: ZipCheck = {
      zipCode: this.zipcodeControl.value,
      street: this.streetControl.value,
      country: this.countryControl.value,
    };

    this.addressService.canValidateAddress(zipCheck)
      .pipe(finalize(() => this.isLoading = false))
      .subscribe();
  }

  private addValueChangeListeners() {
    this.zipcodeValueChangeSubscription = this.zipcodeControl.valueChanges.pipe(
      filter(value => value.length === 4),
    ).subscribe(() => this.findByZipCode());
  }

}
