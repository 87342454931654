<div [formGroup]="addressForm" class="svnl-address-check">
  <mat-label translate="funnel.generic.zipcode"></mat-label>
  <mat-form-field>
    <input [placeholder]="'funnel.generic.zipcode' | translate" formControlName="zipCode" matInput>
    <span *ngIf="isLoading" matSuffix>
      <i class="fa-solid fa-circle-notch fa-spin"></i>
    </span>
    <mat-error *ngIf="zipcodeControl.hasError('required')"
               translate="funnel.profile.form.error.zipcode.required">
    </mat-error>
    <mat-error *ngIf="zipcodeControl.invalid && zipcodeControl.value" translate="funnel.profile.form.error.zipcode.invalid"></mat-error>
  </mat-form-field>
  <div *ngIf="zipIsInvalid" class="svnl-alert svnl-warning">
    {{ zipcheckErrorMessage | translate }}
  </div>


  <mat-label>{{'funnel.generic.city' | translate}}</mat-label>
  <mat-form-field>
    <mat-select formControlName="city">
      <mat-option *ngFor="let select of citySelect" [value]="select.city">
        {{select.city}}
      </mat-option>
    </mat-select>
    <span matSuffix>
      <i [ngClass]="{ valid : cityControl.valid || cityControl.disabled, invalid : cityControl.invalid }"
         class="fa fa-check-circle"></i>
    </span>
    <mat-error *ngIf="cityControl.hasError('required')" translate="funnel.profile.form.error.city.required">
    </mat-error>
  </mat-form-field>

  <mat-label>{{'funnel.generic.street' | translate}}</mat-label>
  <mat-form-field>
    <input type="text"
           [placeholder]="'funnel.generic.street' | translate"
           matInput
           formControlName="street"
           required
           [matAutocomplete]="Street">
    <mat-autocomplete #Street="matAutocomplete">
      <mat-option *ngFor="let option of streetSelect" [value]="option.street">
        {{option.street}}
      </mat-option>
    </mat-autocomplete>
    <span matSuffix>
        <i [ngClass]="{ valid : streetControl.valid || streetControl.disabled, invalid : streetControl.invalid }"
           class="fa fa-check-circle"></i>
    </span>
    <mat-error *ngIf="streetControl.hasError('required')" translate="funnel.profile.form.error.street.required">
    </mat-error>
  </mat-form-field>

  <mat-label>{{'funnel.generic.houseNumber' | translate}}</mat-label>
  <mat-form-field>
    <input type="text"
           [placeholder]="'funnel.generic.houseNumber' | translate"
           matInput
           formControlName="housenumber"
           required>
    <mat-error *ngIf="housenumberControl.hasError('required')"
               translate="funnel.profile.form.error.houseNumber.required">
    </mat-error>
  </mat-form-field>

  <mat-form-field>
    <mat-label>{{'funnel.generic.addition' | translate}}</mat-label>
    <input [placeholder]="'funnel.generic.addition' | translate" formControlName="addition" matInput>
  </mat-form-field>
</div>
